<ion-item tappable (click)="rowSelected(fulltimer)">
  <div tabindex="0"></div>
  <ion-label class="ion-text-wrap">
    <h2 class="candidate_name">{{ fulltimer.fulltimer_name}}</h2>
    <p>{{ fulltimer.fulltimer_email}}</p>
    <p>Registered On: {{ fulltimer.fulltimer_created_datetime}}</p>
    <p>Expected Salary : {{ fulltimer.fulltimer_expected_salary}}</p>
    <p>Gender: {{ (fulltimer.fulltimer_gender == 1) ? 'Male' : 'Female' }}</p>
    <p>Phone: {{ fulltimer.fulltimer_phone}}</p>
    <p *ngIf="fulltimer.country">Country : {{ fulltimer.country.country_name_en }}</p>
    <p *ngIf="fulltimer.nationality">Nationality : {{ fulltimer.nationality.country_name_en }}</p>

    <div *ngIf="suggestion">
      <p>Suggested on: {{suggestion.suggestion_datetime | date}}</p>
      <p>Suggested Status:
        <span *ngIf="suggestion.suggestion_status == 0"> Pending</span>
        <span *ngIf="suggestion.suggestion_status == 1"> Suggested</span>
        <span *ngIf="suggestion.suggestion_status == 2"> Rejected</span>
        <span *ngIf="suggestion.suggestion_status == 3"> Accepted</span>
        <ion-button (click)="changeStatus($event, suggestion,3)" *ngIf="suggestion.suggestion_status != 3 && authService.restrictedAccess()">Accepted</ion-button>
        <ion-button (click)="changeStatus($event, suggestion,2)" *ngIf="suggestion.suggestion_status != 2 && authService.restrictedAccess()">Rejected</ion-button>
        <ion-button (click)="changeStatus($event, suggestion,1)" *ngIf="suggestion.suggestion_status != 1 && authService.restrictedAccess()">Suggested</ion-button>
      </p>
    </div>
  </ion-label>
</ion-item>
