<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button (click)="close()"></ion-back-button>
    </ion-buttons>

    <ion-title>{{ operation }}</ion-title>

    <ion-buttons slot="end">
      <ion-button clear color="primary" 
        [disabled]="!form.valid"
        (click)="save()">
        <span *ngIf="!saving">Save</span>
        <ion-spinner *ngIf="saving"></ion-spinner>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <form [formGroup]="form" *ngIf="form">
    <ion-list>
        <ion-item>
          <ion-label position="floating">Name</ion-label>
          <ion-input type="text" formControlName="name"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="floating">Email</ion-label>
          <ion-input type="text" formControlName="email"></ion-input>
        </ion-item>
        
        <ion-item>
          <div tabindex="0"></div>
            <ion-label>Limited Access</ion-label>
            <ion-toggle (ionChange)="onchange($event)" formControlName="limited_access"></ion-toggle>
        </ion-item>

        <ion-item>
          <div tabindex="0"></div>
            <ion-label>Two Step Auth</ion-label>
            <ion-toggle (ionChange)="onTwoStepAuthChange($event)" formControlName="enable_two_step_auth"></ion-toggle>
        </ion-item>

        <ion-item class="field-password" *ngIf="!model.admin_id">
          <ion-label position="floating">Password</ion-label>
          <ion-input type="{{ type }}" formControlName="password"></ion-input>

          <ion-button class="btn-password" fill="clear" (click)="togglePasswordVisibility()">
            <ion-icon *ngIf="type == 'password'" name="eye"></ion-icon>
            <ion-icon *ngIf="type != 'password'" name="eye-off"></ion-icon>
          </ion-button>
        </ion-item>
    </ion-list>
  </form>

  <loading-modal type="staff-form" [loading]="!form"></loading-modal>
</ion-content>
