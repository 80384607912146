import { Pipe, PipeTransform, NgZone, ChangeDetectorRef, OnDestroy } from '@angular/core';
// services

@Pipe({
    name: 'duration',
    pure: false
})
export class DurationPipe implements PipeTransform {
    public timer: number;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        public ngZone: NgZone,
    ) { }

    transform(start: any, end: any): string {
        this.removeTimer();

        const date1 = new Date(start.replace(/-/g, '/') + ' GMT+03:00')

        const date2 = (end) ? new Date(end.replace(/-/g, '/') + ' GMT+03:00') : new Date();

        const diff = new Date(date2.getTime() - date1.getTime());

        const seconds = Math.round(Math.abs((date2.getTime() - date1.getTime()) / 1000));

        const timeToUpdate = (Number.isNaN(seconds)) ? 1000 : this.getSecondsUntilUpdate(seconds) * 1000;

        this.timer = this.ngZone.runOutsideAngular(() => {
            if (typeof window !== 'undefined') {
                return window.setTimeout(() => {
                    this.ngZone.run(() => this.changeDetectorRef.markForCheck());
                }, timeToUpdate);
            }
            return null;
        });

        const minutes = Math.round(Math.abs(seconds / 60));
        const hours = Math.round(Math.abs(minutes / 60));
        const days = Math.round(Math.abs(hours / 24));
        const months = Math.round(Math.abs(days / 30.416));
        const years = Math.round(Math.abs(days / 365));

        if (Number.isNaN(seconds)){
            return '';
        } else if (seconds <= 45) {
            return 'a few seconds';
        } else if (seconds <= 90) {
            return 'a minute';
        } else if (minutes <= 45) {
            return minutes + 'minutes ago';
        } else if (minutes <= 90) {
            return 'an hour';
        } else if (hours <= 22) {
            return hours + 'hours ago';
        } else if (hours <= 36) {
            return 'a day';
        } else if (days <= 25) {
            return days + 'days ago';
        } else if (days <= 45) {
            return 'a month';
        } else if (days <= 345) {
            return months + 'month ago';
        } else if (days <= 545) {
            return 'a year';
        } else { // (days > 545)
            return years + 'year ago';
        }
    }

    ngOnDestroy(): void {
        this.removeTimer();
    }

    public removeTimer() {
        if (this.timer) {
            window.clearTimeout(this.timer);
            this.timer = null;
        }
    }

    public getSecondsUntilUpdate(seconds: number) {
        const min = 60;
        const hr = min * 60;
        const day = hr * 24;
        if (seconds < min) { // less than 1 min, update every 2 secs
            return 2;
        } else if (seconds < hr) { // less than an hour, update every 30 secs
            return 30;
        } else if (seconds < day) { // less then a day, update every 5 mins
            return 300;
        } else { // update every hour
            return 3600;
        }
    }
}
