<ion-item tappable (click)="rowSelected(candidate)">
  <div tabindex="0"></div>
  <ion-avatar slot="start">
    <img *ngIf="candidate.candidate_personal_photo" src="{{ aws.cloudinaryUrl + 'candidate-photo/' + candidate.candidate_personal_photo }}" (error)="loadLogo($event,candidate)">
    <img *ngIf="!candidate.candidate_personal_photo" src="/assets/icon.png">
  </ion-avatar>
  <ion-label class="ion-text-wrap">
    <h2 class="candidate_name">{{ candidate.candidate_name ?candidate.candidate_name : candidate.candidate_name_ar }}  <small>({{candidate.candidate_id}})</small></h2>
    <p *ngIf="candidate.store">
      {{ candidate.store.store_name }}
      <span *ngIf="candidate.company">@ {{ candidate.company.company_name }} </span>
    </p>

    <ion-badge *ngIf="candidate.isProfileCompleted == false" color="danger">
      Incomplete Profile
    </ion-badge>

    <ion-badge *ngIf="!candidate.candidate_email_verification" color="danger">
      Email Not Verified
    </ion-badge>

    <ion-badge *ngIf="!candidate.approved" color="danger">
      Not Approved
    </ion-badge>

    <ion-badge *ngIf="candidate.deleted" color="danger">
      Deleted
    </ion-badge>
    <div *ngIf="suggestion">
      <p>Suggested on: {{suggestion.suggestion_datetime | date}}</p>
      <p>Suggested Status:
        <span *ngIf="suggestion.suggestion_status == 0">Pending</span>
        <span *ngIf="suggestion.suggestion_status == 1">Suggested</span>
        <span *ngIf="suggestion.suggestion_status == 2">Rejected</span>
        <span *ngIf="suggestion.suggestion_status == 3">Accepted</span>
      </p>
      <ion-button (click)="changeStatus($event, suggestion,3)" *ngIf="suggestion.suggestion_status != 3 && authService.restrictedAccess()">Accepted</ion-button>
      <ion-button (click)="changeStatus($event, suggestion,2)" *ngIf="suggestion.suggestion_status != 2 && authService.restrictedAccess()">Rejected</ion-button>
      <ion-button (click)="changeStatus($event, suggestion,1)" *ngIf="suggestion.suggestion_status != 1 && authService.restrictedAccess()">Suggested</ion-button>
    </div>
  </ion-label>
</ion-item>
